<template lang="pug">
  .plan-details
    ArrowBack(@back="handleClickBack")
    AppOverlayLoader(:state="loading")
    BTabs(
      fill
      vertical
      card
      nav-wrapper-class="nav-wrapper"
    )
      BTab(
        v-for="tab in tabs"
        :title="tabName[tab]"
        :key="tab"
        @click="handleTabClick(tab)"
      )
        .header-row
          .header {{ tabName[tab] }}
          AppIconButton(
            v-if="tab === 'basic_plan_info'"
            disabled
            title="ota_management.plan_list.form.basic_plan_info.languages"
            :iconBefore="false"
            icon="chevron-down"
          )
        PublishSettings(
          v-if="tab === 'publish_settings'"
          :item="publishSettings"
          @update-markups="updateMarkups"
          @set-basic-rate-item="setBasicRateItem"
          @set-special-rate-item="setSpecialRateItem"
        )
        BasicPlanInfo(
          v-if="tab === 'basic_plan_info'"
          :item="plan.basic_plan_info"
        )
</template>

<script>
  // misc
  import { reduce, some, get } from "lodash-es"

  // components
  import { BTabs, BTab } from "bootstrap-vue"

  // mixins
  import withConfirmation from "@/mixins/withConfirmation"
  import withStoreModule from "@/mixins/withStoreModule"

  // store modules
  import planListModule from "@/config/store/maestro/markups/plan_list"

  const planListMixin = withStoreModule(planListModule, {
    name: "markupManagementPlanListMixin",
    readers: {
      plan: "item",
      loading: "loading",
      sorting: "sorting"
    },
    actions: {
      fetchPlan: "FETCH_ITEM",
      updateMarkupsAction: "UPDATE_MARKUPS"
    },
    mutations: {
      setBasicRateItem: "SET_BASIC_RATE_ITEM_BY_INDEX",
      setSpecialRateItem: "SET_SPECIAL_RATE_ITEM_BY_INDEX"
    }
  })

  export default {
    components: {
      BTabs,
      BTab,
      BasicPlanInfo: () => import("./BasicPlanInfo"),
      PublishSettings: () => import("./PublishSettings"),
      ArrowBack: () => import("@/components/CompanySystem/ArrowBack"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    mixins: [planListMixin, withConfirmation],

    data() {
      return {
        tabs: ["publish_settings", "basic_plan_info"],
        currentTab: "publish_settings"
      }
    },

    props: {
      planId: {
        type: Number,
        required: false
      }
    },

    mounted() {
      this.fetchPlan(this.planId)
    },

    methods: {
      handleClickBack() {
        this.$conditionalConfirm({
          useConfirm: this.hasUnsavedChanges,
          handler: () => this.$emit("back")
        })
      },

      handleTabClick(tab) {
        this.currentTab = tab
      },

      updateMarkups() {
        this.updateMarkupsAction(this.plan)
      },

      checkUpdatedMarkupFees(rates = []) {
        return some(rates, "_updated")
      }
    },

    computed: {
      hasUnsavedChanges() {
        return this.hasUnsavedBasicRates || this.hasUnsavedSpecialRates
      },

      hasUnsavedBasicRates() {
        return this.checkUpdatedMarkupFees(this.basicRates)
      },

      hasUnsavedSpecialRates() {
        return some(this.specialRates, ({ rates }) => this.checkUpdatedMarkupFees(rates))
      },

      basicRates() {
        return get(this.publishSettings, "basic_rates")
      },

      specialRates() {
        return get(this.publishSettings, "special_rates")
      },

      publishSettings() {
        return get(this.plan, "publish_settings", {})
      },

      tabName() {
        return reduce(
          this.tabs,
          (obj, tab) => {
            obj[tab] = this.$t(`ota_management.plan_list.form.${tab}.title`)
            return obj
          },
          {}
        )
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .plan-details
    position: relative
    .row
      &.back
        margin-left: 10px

    ::v-deep
      .tabs
        &.row
          flex-wrap: nowrap
      .card-header
        background-color: transparent
        padding: 0.75rem 0
      .nav-link
        border-color: transparent
        &.active
          background-color: transparent
          color: $default-black
          font-weight: 600

        &:hover
          border-color: transparent
      .nav-wrapper
        width: 160px

    .header-row
      display: flex
      justify-content: space-between

      button
        background: $default-turquoise-medium
        border-color: $default-turquoise-medium

        ::v-deep
          .title
            margin-right: 5px
            color: $default-white
          svg
            color: $default-white

      .header
        border-left: 13px $default-purple-light solid
        padding: 6px
        font-weight: 700
        font-size: 16px
        line-height: 24px
        margin-bottom: 24px
</style>
